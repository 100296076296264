<template>
  <div
    class="start"
    :style="containerStyle"
  >
    <img
      :src="info.titleImage ? info.titleImage : ''"
      class="head"
    />
    <div class="body">
      <p class="body-title">{{ info.title }}</p>
      <!-- <div class="body-line"></div> -->
      <p class="body-sub" v-if="info.questionnaireNote">
        {{ info.questionnaireNote }}
      </p>
      <!-- <div class="body-line" v-if="info.questionnaireNote"></div> -->
      <van-field
        v-for="(item, index) in this.info.questionnaireInfoCollects"
        :key="index"
        v-model="item.infoValue"
        :label="`${item.infoName}：`"
        class="my-field"
        :required="item.isFill == 1 ? true : false"
      />
      <div class="body-button">
        <!-- <van-button class="body-button-edit" @click="start(1)"
          >编辑并保存</van-button
        > -->
        <!-- <van-button
          class="body-button-start"
          color="linear-gradient(90deg, #FF891C, #FF5508)"
          @click="start(2)"
          >开始答题</van-button
        > -->
        <van-button
          class="body-button-start"
          color="linear-gradient(90deg, #FF891C, #FF5508)"
          @click="start(2)"
        >开始填写</van-button>
      </div>
    </div>
    <van-popup
      v-model="areaShow"
      position="bottom"
    >
      <van-area
        title="选择地址"
        :area-list="areaList"
        @confirm="confirmArea"
        @cancel="cancelArea"
      />
    </van-popup>
  </div>
</template>
<script>
import { areaList } from '@vant/area-data';
import { editInfo, addAnonymityUser } from '@/api/login';
import { mapActions } from 'vuex';
import deepCopy from '@/utils/deepCopy';
import { getInfo } from '@/api/questionnaire.js'
export default {
  data() {
    return {
      form: {
        cssdAddr: ''
      },
      userInfo: {},
      info: {},
      areaShow: false,
      areaList: areaList
    };
  },
  created() {
    this.userInfo = {
      id: this.$store.getters.user.id
    };
    console.log(this.$store.getters.user);
    this.info = this.$store.getters.getQuestionnaireInfo;
    document.title = this.info.title;
    getInfo(this.$store.getters.user.id,this.$route.query.id).then((res) => {
      let result = res.result;
      for (const item of this.info.questionnaireInfoCollects) {
        for (const items of result) {
          if (item.id == items.collectId) {
            this.$set(item,'infoValue',items.infoValue)
          }
        }
      }
    })
  },
  computed: {
    containerStyle() {
      if (this.info.backgroundImage) {
        return { backgroundImage: `url('${this.form.backgroundImage}')` };
      } else {
        return { backgroundColor: this.info.backgroundColor };
      }
    }
  },
  methods: {
    ...mapActions(['setUser']),
    selectArea() {
      this.areaShow = true;
    },
    confirmArea(e) {
      this.form.cssdAddr = e[2].name;
      this.areaShow = false;
    },
    cancelArea() {
      this.areaShow = false;
    },
    start(query) {

      let params = deepCopy(this.info.questionnaireInfoCollects);

      for (const item of params) {
        if (item.isFill == 1 && !item.infoValue) {
          this.$toast.fail(`请输入${item.infoName}！`);
          return;
        }
        item.userId = this.userInfo.id;
        if (!item.infoValue) {
          item.infoValue = '';
        }
        item.collectId = item.id;
        delete item.questionnaireId;
        delete item.id;
        delete item.infoName;
      }
      addAnonymityUser(params).then(res => {
        // this.$store.dispatch('setUser', res.result);
        if (res && res.success) {
          this.$toast.success('保存成功！');
          if (query == 2) {
            this.$router.push({
              path: '/questionnaire/realName/main',
              query: this.$route.query
            });
          }
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.start {
  overflow: hidden;
  height: auto;
  min-height: 100vh;
  .head {
    margin-top: 54px;
    width: 100%;
  }
  .body {
    width: 1172px;
    // margin-top: 100px;
    margin-left: 56px;
    border-radius: 56px;
    background-color: white;
    padding: 0 54px 160px;
    overflow: hidden;
    &-title{
      width: 90%;
      margin-top: 95px;
      margin-left: 5%;
      font-size: 55px;
      font-family: Source Han Sans CN;
      font-weight: 600;
      color: black;
      text-align: center;
    }
    &-sub {
      width: 90%;
      margin-top: 95px;
      margin-left: 5%;
      font-size: 48px;
      font-family: Source Han Sans CN;
      font-weight: 300;
      color: #1a1a1a;
      line-height: 88px;
      word-break: break-all;
      white-space: normal;
      word-wrap: normal;
    }
    &-line {
      width: 997px;
      height: 2px;
      background: #e6e6e6;
      margin-left: 88px;
    }

    &-area {
      width: 100%;
      &-icon {
        float: right;
        line-height: 48px;
      }
    }
    &-button {
      // width: 755px;
      // margin-left: 154px;
      width: 100%;
      text-align: center;
      font-size: 48px;
      margin-top: 160px;
      text-align: center;
      &-edit {
        width: 320px;
        height: 128px;
        background: #ffffff;
        border: 2px solid #b5b5b6;
        border-radius: 64px;
        span {
          color: #b5b5b6;
        }
      }
      &-start {
        width: 320px;
        height: 128px;
        border-radius: 64px;
        // margin-left: 115px;
      }
    }
  }
}
.my-field {
  flex-direction: column;
}
::v-deep .van-field__label {
  width: auto;
  font-size: 48px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #646566;
}
::v-deep .van-button--normal {
  padding: 0 !important;
}
</style>
